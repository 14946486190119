import React, { useContext } from "react"

import Medusa from "../services/api"

export const defaultAccountContext = {
  loggedIn: undefined,
  id: "",
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  metadata: {},
  orders: [],
  billingAddressId: "",
  billingAddress: {},
  savedPaymentMethods: [],
  savedAddresses: [],
  isStaff: undefined,
  listWishlist: () => { },
  retrievePaymentMethods: () =>
    Promise.resolve({ data: { payment_methods: [] } }),
}

export const AccountContext = React.createContext(defaultAccountContext)

export const useAccountContext = () => useContext(AccountContext)

class AccountProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...defaultAccountContext,
      onLogin: [],
      addOnLogin: (event, data) => {
        this.setState((prev) => ({
          onLogin: [...prev.onLogin, { event, data }],
        }))
      },
      removeFromWishList: async ({ variantId }) => {
        const index = this.state.metadata.wishlist.findIndex(
          (variant) => variant.variant_id === variantId
        )
        const { data } = await Medusa.customers.removeFromWishList(
          this.state.id,
          {
            index,
          }
        )
        this.state.setUser(data.customer)

        localStorage.setItem(
          "medusa::cache::wishlist",
          JSON.stringify(data?.customer?.metadata?.wishlist?.length)
        )

        return data
      },
      addToWishList: async ({ variantId, metadata = {}, config = {} }) => {
        // check if the item is added
        let existingItem
        if (
          this.state.metadata?.wishlist?.some((variant) => {
            if (variant.variant_id === variantId) {
              existingItem = variant
              return true
            }
          })
        ) {
          throw new Error(`${existingItem.title} - ${existingItem.description}`)
        }
        const userId = config?.userId ?? this.state.id

        const { data } = await Medusa.customers.addToWishList(userId, {
          variant_id: variantId,
          quantity: 1,
          metadata: metadata,
        })

        this.state.setUser(data.customer)
        return data
      },
      logOut: async () => {
        await Medusa.auth.delete()
        this.state.setUser({})
      },
      updatePassword: async (password) => {
        const { data } = await Medusa.customers.update(this.state.id, {
          password,
        })
        this.state.setUser(data.customer)
        return data
      },
      setPassword: (data) => {
        return Medusa.customers.resetPassword(data)
      },
      retrievePaymentMethods: () => {
        if (!this.state.id) {
          return Promise.resolve({ data: { payment_methods: [] } })
        }
        return Medusa.customers.paymentMethods.list(this.state.id)
      },
      pushAddress: async (address) => {
        const { data } = await Medusa.customers.addresses.create(
          this.state.id,
          address
        )
        return this.state.setUser(data.customer)
      },
      updateAddress: async (addressId, address) => {
        const { data } = await Medusa.customers.addresses.update(
          this.state.id,
          addressId,
          address
        )
        return this.state.setUser(data.customer)
      },
      deleteAddress: async (addressId) => {
        const { data } = await Medusa.customers.addresses.delete(
          this.state.id,
          addressId
        )
        return this.state.setUser(data.customer)
      },
      forgotPassword: (email) => {
        return Medusa.customers.resetPasswordToken(email)
      },
      logIn: async (email, password) => {
        const { data } = await Medusa.auth.create(email, password)
        Medusa.customers
          .me()
          .then(({ data }) => this.state.setUser(data.customer))

        Medusa.customers.properties(data.customer.id).then(({ data }) => {
          this.state.setIsStaff(data?.is_staff)
        })

        const response = data

        return await response
      },
      updateDetails: async (update) => {
        const { data } = await Medusa.customers.update(this.state.id, update)
        this.state.setUser(data.customer)
        return data
      },
      setOrders: (orders) => {
        this.setState({
          orders,
        })
      },
      setIsStaff: (isStaff) => {
        this.setState({
          isStaff: isStaff ? true : false,
        })
      },
      setUser: (user) => {
        localStorage.setItem("tekla::user", JSON.stringify(user))
        if (user.id) {
          this.setState({
            loggedIn: true,
            id: user.id,
            email: user.email,
            orders: user.orders,
            metadata: user.metadata,
            billingAddressId: user.billing_address_id,
            billingAddress: user.billing_address,
            savedAddresses: user.shipping_addresses || [],
            name: `${user.first_name} ${user.last_name}`,
            firstName: user.first_name,
            lastName: user.last_name
          })
        } else {
          this.setState({
            ...defaultAccountContext,
            loggedIn: false,
            isStaff: false,
          })
        }
      },
    }
  }

  componentDidMount() {
    if (localStorage) {
      const savedUser = localStorage.getItem("tekla::user")
      if (savedUser) {
        const user = JSON.parse(savedUser)
        this.state.setUser(user)
      }
    }
    Medusa.auth
      .retrieve()
      .then(({ data }) => {
        const customer = data.customer
        this.state.setUser(customer)

        Medusa.customers.listOrders(customer.id).then(({ data }) => {
          this.state.setOrders(data.orders)
        })

        Medusa.customers.properties(customer.id).then(({ data }) => {
          this.state.setIsStaff(data?.is_staff)
        })
      })
      .catch(() => {
        this.state.setUser({})
      })
  }
  render() {
    return (
      <AccountContext.Provider value={this.state}>
        {this.props.children}
      </AccountContext.Provider>
    )
  }
}

export default AccountProvider
